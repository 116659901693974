const initialState = {
  list: [],
};

export const VENDOR_ACTION_TYPES = {
  SET_LIST: '@vendors/set-list',
  RESET: '@loader/reset-list',
};

export const VENDOR_ACTIONS = {
  setList: (payload) => ({ type: VENDOR_ACTION_TYPES.SET_LIST, payload }),
  reset: () => ({ type: VENDOR_ACTION_TYPES.RESET }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case VENDOR_ACTION_TYPES.SET_LIST:
      return { ...state, list: payload };

    case VENDOR_ACTION_TYPES.RESET:
      return { ...initialState };

    default:
      return state;
  }
};

import { result } from 'lodash';
import messages from './i18n_en.json';

/**
 * Internationalization class
 * @note: Keep all methods static (so thay can be accessed directly)
 * @author Sagar Panchal
 */
export class i18n {
  /**
   * get translation
   * @param {String} accessor object path
   */
  static t(accessor) {
    return result(messages, accessor);
  }
}

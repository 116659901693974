import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import invert from 'lodash/invert';

import Storage from 'services/storage';

import { i18n } from 'util/i18n';
import { isEmpty, isArray } from 'util/utils';

import { MENU } from './Menu';

export const USER_PERMISSION = [
  {
    module: 'DASHBOARD',
    title: 'Dashboard',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SEARCH_DIAMOND',
    title: 'Search Diamond',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'QUICK_SEARCH',
    title: 'Quick Search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'FANCY_SEARCH',
    title: 'Fancy Search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SAVE_SEARCH',
    title: 'Save Search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'DEMAND',
    title: 'Demand',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'ENQUIRY',
    title: 'Enquiry',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SEARCH_LIST',
    title: 'Search List',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'COMPARE',
    title: 'Compare',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'MATCH_PAIR',
    title: 'Match Pair',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },

  {
    module: 'BID',
    title: 'BID IT',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'NEW_ARRIVAL',
    title: 'New Arrival',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'EXCLUSIVE',
    title: 'Exclusive',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'UPCOMING',
    title: 'Upcoming Diamonds',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'CART',
    title: 'Cart',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'HOLD',
    title: 'Hold',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'MEMO',
    title: 'Memo',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'WATCHLIST',
    title: 'Watchlist',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'NOTES',
    title: 'Notes',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'CONFIRM_STONE',
    title: 'Confirm Stone',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SHIPMENT',
    title: 'Shipment',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'QUOTE_DAY',
    title: 'Quote Day',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'PRINT',
    title: 'Print',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'QUOTE',
    title: 'Quote ',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'MY_BID',
    title: 'My Bid',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'OFFICE',
    title: 'Office',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'FINAL_CALCULATIONS',
    title: 'Final Calculations',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SHARE_VIA_MAIL',
    title: 'Share via mail',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SHARE_VIA_WHATSAPP',
    title: 'Share via wtsap',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SHARE_VIA_SKYPE',
    title: 'Share via skype',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'DOWNLOAD',
    title: 'Download',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'NOTIFICATIONS',
    title: 'Notification',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'REMINDER',
    title: 'Reminder',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SUGGESTED_STOCK',
    title: 'Suggested stock',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'SEARCH_MATCH_PAIR',
    title: 'Search Match Pair',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'HIDE_GRID_COLUMNS',
    title: 'Hide Grid Columns',
    permissions: {
      org: false,
      ftc: false,
      mines: false,
    },
  },
  {
    module: 'STONE_OF_THE_DAY',
    title: 'Stone Of The Day',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    module: 'COLLECTION',
    title: 'Collection',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
];

export const PERMISSION = [
  // {
  //   mainModule: 'DASHBOARD',
  //   module: 'DASHBOARD',
  //   url: '/dashboard/home',
  //   title: 'Dashboard',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'DASHBOARD',
  //   module: 'ENQUIRY',
  //   url: '/dashboard/enquiry',
  //   title: 'Enquiry',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'DASHBOARD',
  //   module: 'INVENTORY',
  //   url: '/dashboard/inventory',
  //   title: 'Inventory',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'DASHBOARD',
    module: 'SEARCH',
    title: 'Search',
    url: '/dashboard/search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'DASHBOARD',
  //   module: 'ANALYSIS',
  //   title: 'Analysis',
  //   url: '/dashboard/analysis',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'DASHBOARD',
    subModule: 'ANALYSIS',
    module: 'SALES_REPORT',
    title: 'Sales Report',
    url: '/dashboard/analysis/sales-report',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },

  {
    mainModule: 'DASHBOARD',
    subModule: 'ANALYSIS',
    module: 'USER_ACTIVITY_ANALYSIS',
    title: 'User Activity Analysis',
    url: '/dashboard/analysis/user-activity-analysis-report',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },

  {
    mainModule: 'DASHBOARD',
    subModule: 'ANALYSIS',
    module: 'NEW_ARRIVAL',
    title: 'New Arrival',
    url: '/dashboard/analysis/new-arrival-report',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'DASHBOARD',
    subModule: 'ANALYSIS',
    module: 'DAILY_VISIT_REPORT',
    title: 'Daily Visit Report',
    url: '/dashboard/analysis/daily-visit-report',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // INVENTORY/SINGLE_STONE
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'OVERVIEW',
    title: 'Overview',
    url: '/inventory/single-stone/overview',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SEARCH',
    title: 'Search',
    url: '/inventory/single-stone/search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'QUICK_SEARCH',
    title: 'Quick Search',
    url: '/inventory/single-stone/quick-search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'NEW_ARRIVAL',
    title: 'New Arrival',
    url: '/inventory/single-stone/new-arrival',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'SINGLE_STONE',
  //   module: 'UPCOMING',
  //   title: 'Upcoming',
  //   url: '/inventory/single-stone/upcoming',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     isShowBack: true,
  //   },
  // },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SAVED_SEARCH',
    title: 'Saved Search',
    url: '/inventory/single-stone/saved-search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'MY_DEMAND',
    title: 'My Demand',
    url: '/inventory/single-stone/my-demand',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'MATCH_PAIR',
    title: 'Match Pair',
    url: '/inventory/single-stone/match-pair',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'SINGLE_STONE',
  //   module: 'SPECIAL_STONE',
  //   title: 'Special Stone',
  //   url: '/inventory/single-stone/special-stone',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SEARCH_LAYOUT',
    title: 'Calibrated Diamond',
    url: '/inventory/single-stone/calibrated-diamond',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SHOW_STONE_REPORT',
    title: 'Show Stone Report',
    url: '/inventory/single-stone/show-stone-report',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'QUOTE_DAY',
    title: `${i18n.t('title.offer')} Day`,
    url: '/inventory/single-stone/quote-day',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // INVENTORY/STOCK_SUMMARY
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'SUMMARY',
    title: 'Summary',
    url: '/inventory/stock-summary/overview',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'DIST_CRITERIA',
    title: 'Distribution Criteria',
    url: '/inventory/stock-summary/distribution-criteria',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'BLUENILE',
    title: 'BlueNile',
    url: '/inventory/stock-summary/bluenile',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'JAMESALLEN',
    title: 'James Allen',
    url: '/inventory/stock-summary/jamesallen',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'STOCK_SUMMARY',
    module: 'NO_DIST',
    title: 'Not Distributed',
    url: '/inventory/stock-summary/not-distributed',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // INVENTORY/ANALYTICS
  {
    mainModule: 'INVENTORY',
    subModule: 'ANALYTICS',
    module: 'ANALYTICS',
    title: 'Analytics',
    url: '/inventory/analytics',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // INVENTORY/CONFIGURATION
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    title: 'Sync From Individual Inventory',
    module: 'SYNC_FROM_INDIVIDUAL_INVENTORY',
    url: '/inventory/configuration/online-inventory',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CREATE_COLLECTIONS',
    title: 'Create Collections',
    url: '/inventory/configuration/create-collection',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'TERMS_DETAILS',
    title: 'Terms Details',
    url: '/inventory/configuration/terms-details',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'CONFIGURATION',
  //   module: 'VOLUME_DISCOUNT',
  //   title: 'Volume Discount',
  //   url: '/inventory/configuration/volume-discount',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'FEATURE_STONE',
    title: 'Feature Stone',
    url: '/inventory/configuration/feature-stone',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'BID_CONFIG',
    title: 'Bid Config',
    url: '/inventory/configuration/bid-config',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'BID_STONE',
    title: 'Bid Stone',
    url: '/inventory/configuration/bid-stone',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'FEATURE_STONE',
    title: 'Feature Stone',
    url: '/inventory/configuration/feature-stone',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'CONFIGURATION',
  //   module: 'CARAT_RANGE',
  //   title: 'Carat Range',
  //   url: '/inventory/configuration/carat-range',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'CONFIGURATION',
  //   module: 'CUT_GROUP',
  //   title: 'Cut Group',
  //   url: '/inventory/configuration/cut-group',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'CONFIGURATION',
  //   module: 'RAPNET_POLICY',
  //   title: 'Rapnet Policy',
  //   url: '/inventory/configuration/rapnet-policy',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'CONFIGURATION',
  //   module: 'CHECK_IMAGE',
  //   title: 'Check Image',
  //   url: '/inventory/configuration/check-image-status',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'CONFIGURATION',
  //   module: 'CERTIFICATE_UPLOAD',
  //   title: 'Certificate Upload',
  //   url: '/inventory/configuration/upload-typeIIA-cert',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'DIAMOND_COLUMN_SETTING',
    title: 'Diamond Column Setting',
    url: '/inventory/configuration/diamond-column-setting',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'DOWNLOAD_EXCEL',
    title: 'Download Excel',
    url: '/inventory/configuration/download-Excel',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'QUOTE_DAY',
    title: `${i18n.t('title.offer')} Day`,
    url: '/inventory/configuration/quote-day',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'CONFIGURATION',
  //   module: 'CHECK_PACKET',
  //   title: 'Check Packet',
  //   url: '/inventory/configuration/check-packet',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'INVENTORY',
  //   subModule: 'CONFIGURATION',
  //   module: 'MATCH_PAIR_CONFIGURE',
  //   title: 'Match Pair',
  //   url: '/inventory/configuration/match-pair-setting',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'GENERAL',
    title: 'General',
    url: '/client/kyc/basic',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BUSINESS_DETAILS',
    title: 'Business Details',
    url: '/client/kyc/business-detail',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BANKS',
    title: 'Banks',
    url: '/client/kyc/bank-info',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BRANCH',
    title: 'Branch',
    url: '/client/kyc/branch',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'USER',
    title: 'User',
    url: '/client/kyc/user',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'REFERENCES',
    title: 'References',
    url: '/client/kyc/references-detail',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'KYC',
  //   module: 'SETTING',
  //   title: 'Setting',
  //   url: '/client/kyc/setting',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'KYC',
  //   module: 'TERMS_DETAIL',
  //   title: 'Terms Details',
  //   url: '/client/kyc/terms-detail',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'ENQUIRY',
  //   module: 'HOLD',
  //   title: 'Hold',
  //   url: '/client/enquiry/hold',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     confirm: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'ENQUIRY',
  //   module: 'MEMO',
  //   title: 'Memo',
  //   url: '/client/enquiry/memo',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     confirm: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'ENQUIRY',
  //   module: 'DEMAND',
  //   title: 'Demand',
  //   url: '/client/enquiry/demand',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'TRANSACTION',
  //   module: 'CART',
  //   title: 'Cart',
  //   url: '/client/transactions/cart',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'WATCHLIST',
    title: 'Watchlist',
    url: '/client/transactions/cart',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'NOTES',
    title: 'Notes',
    url: '/client/transactions/notes',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'TRANSACTION',
  //   module: 'OFFICE_VIEW',
  //   title: 'Office View',
  //   url: '/client/transactions/officeview',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'TRANSACTION',
  //   module: 'SEEN_STONES',
  //   title: 'Seen Stones',
  //   url: '/client/transactions/seen-stones',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'TRANSACTION',
  //   module: 'SUGGESTED_STOCK',
  //   title: 'Suggested Stock',
  //   url: '/suggested-stock',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'TRANSACTION',
  //   module: 'ACCURATE_STOCK',
  //   title: 'Accurate Stock',
  //   url: '/accurate-stock',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'CONFIRM_STONE',
  //   module: 'CONFIRM_STONE',
  //   title: 'Confirm Stone',
  //   url: '/client/order/confirmed',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'CONFIRM_STONE',
  //   module: 'CONFIRM_STONE',
  //   url: '/client/order/confirmed',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'CONFIRM_STONE',
  //   module: 'REJECTED',
  //   url: '/client/order/rejected',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'QUOTE',
  //   module: 'PENDING',
  //   title: 'Pending',
  //   url: '/client/offer/pending',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     isShowClientColumn: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'QUOTE',
  //   module: 'APPROVED',
  //   title: 'Approved',
  //   url: '/client/offer/approved',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     isShowClientColumn: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'QUOTE',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/client/offer/rejected',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     isShowClientColumn: true,
  //   },
  // },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'SAVED_SEARCH',
    title: 'Saved Search',
    url: '/client/search/saved-search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'RECENT_SEARCH',
    title: 'Recent Search',
    url: '/client/search/recent-search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'CLIENT_PREFERANCE',
    title: 'Client Preference',
    url: '/recent-search',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'ANALYTICS',
  //   module: 'ANALYTICS',
  //   title: 'Analytics',
  //   url: '/client/analytics',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'CLIENT',
  //   subModule: 'SUGGESTED_STOCK',
  //   module: 'SUGGESTED_STOCK',
  //   title: 'Suggested Stock',
  //   url: '/client/suggested-stock',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'TRANSACTION',
    subModule: 'QUOTE',
    module: 'PENDING',
    title: 'Pending',
    url: '/transaction/offer/pending',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
      isShowClientColumn: true,
    },
  },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'QUOTE',
  //   module: 'APPROVED',
  //   title: 'Approved',
  //   url: '/transaction/offer/approved',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     isShowClientColumn: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'QUOTE',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/transaction/offer/rejected',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     isShowClientColumn: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'QUOTE',
  //   module: 'COUNTER',
  //   title: 'Counter Offer',
  //   url: '/transaction/offer/counter-offer',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     isShowClientColumn: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'CONFIRM_STONE',
  //   module: 'CONFIRM_STONE',
  //   title: 'Confirm Stone',
  //   url: '/transaction/order/confirmed',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'ENQUIRY',
  //   module: 'CART',
  //   title: 'Cart',
  //   url: '/transaction/enquiry/cart',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'WATCHLIST',
    title: 'Watchlist',
    url: '/transaction/enquiry/watchlist',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'ENQUIRY',
  //   module: 'SEEN_STONES',
  //   title: 'Seen Stones',
  //   url: '/transaction/enquiry/seen-stones',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'NOTES',
    title: 'Notes',
    url: '/transaction/enquiry/notes',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'OFFICE_VIEW',
  //   module: 'PENDING',
  //   title: 'Upcoming',
  //   url: '/transaction/office-view/upcoming',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'OFFICE_VIEW',
  //   module: 'APPROVED',
  //   title: 'Approved',
  //   url: '/transaction/office-view/approved',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'OFFICE_VIEW',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/transaction/office-view/rejected',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'OFFICE_VIEW',
  //   module: 'EXPIRED',
  //   title: 'Expired',
  //   url: '/transaction/office-view/expired',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'RUNNING',
    title: 'Running',
    url: '/transaction/bid/running',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'RESULTS',
    title: 'Results',
    url: '/results',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'HISTORY',
    title: 'History',
    url: '/transaction/bid/history',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'MYCLIENTBID',
    title: 'My Client Bid',
    url: '/transaction/bid/my-client-bid',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'INPROCESS',
    title: 'In Process',
    url: '/transaction/bid/in-process',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/bid/rejected',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'BIDCART',
    title: 'My Client Bid Cart',
    url: '/transaction/bid/bid-cart',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'DEALOFTHEDAY',
    module: 'RUNNING',
    title: 'Running',
    url: '/transaction/deal-of-the-day/running',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'DEALOFTHEDAY',
    module: 'RESULTS',
    title: 'Results',
    url: '/results',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'DEALOFTHEDAY',
    module: 'HISTORY',
    title: 'History',
    url: '/transaction/deal-of-the-day/history',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'DEALOFTHEDAY',
    module: 'MYDEALOFTHEDAY',
    title: 'My Client Bid',
    url: '/transaction/deal-of-the-day/my-deal',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'DEALOFTHEDAY',
    module: 'INPROCESS',
    title: 'In Process',
    url: '/transaction/deal-of-the-day/in-process',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'DEALOFTHEDAY',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/deal-of-the-day/rejected',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'ENQUIRY',
    module: 'CONFIRM',
    url: '/enquiry/confirm',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'ENQUIRY',
    module: 'NOTES',
    url: '/enquiry/notes',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'ENQUIRY',
    module: 'CUSTOMER_HISTORY',
    url: '/enquiry/customer-history',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'ENQUIRY',
    subModule: 'KYC',
    module: 'GENERAL',
    url: '/enquiry/kyc/basic',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'ENQUIRY',
    subModule: 'QUOTE',
    module: 'APPROVED',
    url: '/transaction/offer/approved',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'HOLD',
  //   module: 'PENDING',
  //   title: 'Pending',
  //   url: '/transaction/hold/pending',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     confirm: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'HOLD',
  //   module: 'APPROVED',
  //   title: 'Approved',
  //   url: '/transaction/hold/approved',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     confirm: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'HOLD',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/transaction/hold/rejected',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     confirm: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'HOLD',
  //   module: 'RELEASED',
  //   title: 'Released',
  //   url: '/transaction/hold/released',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     confirm: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'MEMO',
  //   module: 'CONSIGNMENT',
  //   title: 'Consignment',
  //   url: '/transaction/memo/consignment',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     confirm: true,
  //   },
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'MEMO',
  //   module: 'OFFICE',
  //   title: 'Office',
  //   url: '/transaction/memo/office',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //     confirm: true,
  //   },
  // },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ECS_REPORT',
    module: 'E',
    title: 'E',
    url: '/transaction/report/e',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ECS_REPORT',
    module: 'C',
    title: 'C',
    url: '/transaction/report/c',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ECS_REPORT',
    module: 'S',
    title: 'S',
    url: '/transaction/report/s',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'PROFORMA_REPORT',
    module: 'OK',
    title: 'Ok',
    url: '/ok',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    module: 'TRACK_SHIPMENT',
    title: 'Ok',
    url: '/transaction/track-shipment',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'PROFORMA_REPORT',
    module: 'PENDING',
    title: 'Pending',
    url: '/transaction/proforma-report/pending',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'PRICING',
  //   module: 'REVISE',
  //   title: 'Revise',
  //   url: '/pricing/revise',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'NOTIFICATION',
    module: 'BULK_EMAIL',
    title: 'Bulk Email',
    url: '/notification/bulk-email',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'NEW',
    title: 'Unseen',
    url: '/unseen',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'CLEARED',
    title: 'Cleared',
    url: '/seen',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TODO',
    subModule: 'KYC',
    module: 'PENDING',
    title: 'Pending',
    url: '/todo/kyc/pending',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TODO',
    subModule: 'KYC',
    module: 'INPROGRESS',
    title: 'In Progress',
    url: '/todo/kyc/inprogress',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TODO',
    subModule: 'KYC',
    module: 'CLOSED',
    title: 'Closed',
    url: '/todo/kyc/closed',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'USER',
    module: 'OVERVIEW',
    title: 'Users',
    url: '/user/overview',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'USER',
    module: 'ACTIONS',
    title: 'Actions',
    url: '/user/actions',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'USER',
    module: 'PERFORMANCE',
    title: 'Performance',
    url: '/user/performance',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'BASIC_DETAILS',
    title: 'Basic Details',
    url: '/user/configuration/user-infomation',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'PERMISSIONS',
    title: 'Permissions',
    url: '/user/configuration/permissions',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'CHANGE_PASSOWRD',
    title: 'Change Password',
    url: '/user/configuration/change-password',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'USER',
    subModule: 'BEHAVIOUR',
    module: 'ACTIVITY',
    title: 'Activity',
    url: '/user/user-behaviour/activity',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'USER',
    subModule: 'BEHAVIOUR',
    module: 'LOGIN_LOG',
    title: 'Login Log',
    url: '/user/user-behaviour/login-log',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SHORTCUTS',
    module: 'CLIENT_KYC',
    url: '/user/configuration/user-infomation',
    title: 'Clients / KYC',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SHORTCUTS',
    module: 'USER',
    url: '/user/configuration/user-infomation',
    title: 'User',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'SETTING',
  //   // subModule: 'MY_SETTING',
  //   module: 'MY_SETTING',
  //   title: 'My Settings',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'SETTING',
    // subModule: 'NOTIFICATION',
    module: 'NOTIFICATION',
    title: 'Notification',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'CRM',
  //   module: 'TARGET',
  //   title: 'Target',
  //   url: '/target',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'SETTING',
    // subModule: 'MY_ACCOUNT',
    module: 'MY_ACCOUNT',
    title: 'My Account',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SETTING',
    // subModule: 'TERMS_AND_CONDITIONS',
    module: 'TERMS_AND_CONDITIONS',
    title: 'Terms And Conditions',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'NEW',
    title: 'Unseen',
    url: '/unseen',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'CLEARED',
    title: 'Cleared',
    url: '/seen',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'MASTER',
    title: 'Master',
    url: '/setting/master',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'SUB_MASTER',
    title: 'Sub Master',
    url: '/setting/submaster',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'SETTING',
  //   subModule: 'MY_SETTING',
  //   module: 'CAREER',
  //   title: 'Career',
  //   url: '/career',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'SETTING',
  //   subModule: 'MY_SETTING',
  //   module: 'CAREER_APPLICATIONS',
  //   title: 'Applications',
  //   url: '/applications',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'SETTING',
  //   subModule: 'MY_SETTING',
  //   module: 'FEEDBACK',
  //   title: 'Feedback',
  //   url: '/feedback',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'ROLE_PERMISSION',
    title: 'Role Permission',
    url: '/rolepermission',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'SETTING',
  //   subModule: 'MY_SETTING',
  //   module: 'MY_SETTING',
  //   url: '/setting/profile',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'CONTACT_DIRECTORY',
    title: 'Contact Directory',
    url: '/setting/contact-directory',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'HOME_SETTING',
    title: 'Home Settings',
    url: '/setting/home-setting',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'PROJECT_SETTING',
    title: 'Project Settings',
    url: '/project-setting',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'PROMOTIONAL_SETTING',
    title: 'Promotional Settings',
    url: '/promotional-setting',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'QUOTE_SETTING',
    title: 'Quote Settings',
    url: '/quote-setting',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'EMAIL_CONFIGURATION',
    title: 'Email Configuration',
    url: '/email-configuration',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  // {
  //   mainModule: 'UTILITY',
  //   module: 'SHIPPING_LABEL',
  //   title: 'Shipping Label',
  //   url: '/utility/shipping-label',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   module: 'CHEQUE_DETAILS',
  //   title: 'Cheque Details',
  //   url: '/utility/cheque-details',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   module: 'RAPNET',
  //   title: 'Rapnet',
  //   url: '/utility/rapnet',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'CHEQUE_DETAILS',
  //   module: 'PENDING',
  //   title: 'Pending',
  //   url: '/utility/cheque-details/pending',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'CHEQUE_DETAILS',
  //   module: 'ACCEPTED',
  //   title: 'Accepted',
  //   url: '/utility/cheque-details/accepted',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'CHEQUE_DETAILS',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/utility/cheque-details/rejected',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'SHIPPING_LABEL',
  //   module: 'PENDING',
  //   title: 'Pending',
  //   url: '/utility/shipping-label/pending',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'SHIPPING_LABEL',
  //   module: 'ACCEPTED',
  //   title: 'Accepted',
  //   url: '/utility/shipping-label/accepted',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'SHIPPING_LABEL',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/utility/shipping-label/rejected',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'RAPNET',
  //   module: 'PENDING',
  //   title: 'Pending',
  //   url: '/utility/rapnet/pending',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'RAPNET',
  //   module: 'ACCEPTED',
  //   title: 'Accepted',
  //   url: '/utility/rapnet/accepted',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  // {
  //   mainModule: 'UTILITY',
  //   subModule: 'RAPNET',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/utility/rapnet/rejected',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },

  // {
  //   mainModule: 'ACTIVITIES',
  //   module: 'TARGET',
  //   title: 'Target',
  //   url: '/target',
  //   permissions: {
  //     view: true,
  //     insert: true,
  //     update: true,
  //     delete: true,
  //     uploadExcel: true,
  //     downloadExcel: true,
  //     mailExcel: true,
  //     printPDF: true,
  //     all: true,
  //   },
  // },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'REMINDER',
    title: 'Reminder',
    url: '/reminder',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'ENQUIRY_OPEN',
    title: 'Enquiry Open',
    url: '/transaction/enquiry/enquiry-open',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ENQUIRY',
    module: 'ENQUIRY_CLOSE',
    title: 'Enquiry Close',
    url: '/transaction/enquiry/enquiry-close',
    permissions: {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    },
  },
];

export const MODULES = {
  TRANSACTION: {
    QUOTE: {
      NAME: 'QUOTE',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        COUNTER: 'COUNTER',
      },
    },
    CONFIRM_STONE: {
      NAME: 'CONFIRM_STONE',
      MODULE: {
        APPROVED: 'CONFIRM_STONE',
      },
    },
    ENQUIRY: {
      NAME: 'ENQUIRY',
      MODULE: {
        CART: 'CART',
        WATCHLIST: 'WATCHLIST',
        NOTES: 'NOTES',
        REMINDER: 'REMINDER',
        SEEN_STONES: 'SEEN_STONES',
        ENQUIRY_OPEN: 'ENQUIRY_OPEN',
        ENQUIRY_CLOSE: 'ENQUIRY_CLOSE',
      },
    },
    OFFICE_VIEW: {
      NAME: 'OFFICE_VIEW',
      MODULE: {
        UPCOMING: 'UPCOMING',
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        EXPIRED: 'EXPIRED',
      },
    },
    BID: {
      NAME: 'BID',
      MODULE: {
        RUNNING: 'RUNNING',
        HISTORY: 'HISTORY',
        RESULTS: 'RESULTS',
        MYCLIENTBID: 'MYCLIENTBID',
        REJECTED: 'REJECTED',
        INPROCESS: 'INPROCESS',
        BIDCART: 'BIDCART',
      },
    },
    DEALOFTHEDAY: {
      NAME: 'DEALOFTHEDAY',
      MODULE: {
        RUNNING: 'RUNNING',
        HISTORY: 'HISTORY',
        RESULTS: 'RESULTS',
        MYDEALOFTHEDAY: 'MYDEALOFTHEDAY',
        REJECTED: 'REJECTED',
        INPROCESS: 'INPROCESS',
      },
    },
    HOLD: {
      NAME: 'HOLD',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        RELEASED: 'RELEASED',
      },
    },
    MEMO: {
      NAME: 'MEMO',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECT: 'REJECT',
        OFFICE: 'OFFICE',
        CONSIGNMENT: 'CONSIGNMENT',
      },
    },
    // SE_REPORT: {
    //   NAME: 'ECS_REPORT',
    //   MODULE: {
    //     E: 'E',
    //     C: 'C',
    //     S: 'S',
    //   },
    // },
    // PROFORMA_REPORT: {
    //   NAME: 'PROFORMA_REPORT',
    //   MODULE: {
    //     OK: 'OK',
    //     PENDING: 'PENDING',
    //   },
    // },
    TRACK_SHIPMENT: {
      NAME: 'TRACK_SHIPMENT',
    },
  },
  PRICING: {
    REVISE: {
      NAME: 'REVISE',
    },
  },
  NOTIFICATIONS: {
    BULK_EMAIL: {
      NAME: 'BULK_EMAIL',
    },
    NEW: {
      NAME: 'NEW',
    },
    SEEN: {
      NAME: 'CLEARED',
    },
  },
  INVENTORY: {
    SINGLE_STONE: {
      NAME: 'SINGLE_STONE',
      MODULE: {
        OVERVIEW: 'OVERVIEW',
        SEARCH: 'SEARCH',
        QUICKSEARCH: 'QUICK_SEARCH',
        NEWARRIVAL: 'NEW_ARRIVAL',
        UPCOMING: 'UPCOMING',
        SAVEDSEARCH: 'SAVED_SEARCH',
        CALIBRATED: 'CALIBRATED',
        PRICEREVISE: 'PRICEREVISE',
        CHECKIMAGE: 'CHECKIMAGE',
        SEARCH_HISTORY: 'SEARCH_HISTORY',
        DEMAND: 'MY_DEMAND',
        CLIENT_PREFERANCE: 'CLIENT_PREFERANCE',
        MATCHPAIR: 'MATCH_PAIR',
        SHOWSTONEREPORT: 'SHOW_STONE_REPORT',
        QUOTEDAY: 'QUOTE_DAY',
        MATCHPAIRSEARCH: 'MATCHPAIRSEARCH',
        SEARCH_LAYOUT: 'SEARCH_LAYOUT',
      },
    },
    // STOCK_SUMMARY: {
    //   NAME: 'STOCK_SUMMARY',
    //   MODULE: {
    //     SUMMARY: 'SUMMARY',
    //     DIST_CRITERIA: 'DIST_CRITERIA',
    //     BLUENILE: 'BLUENILE',
    //     JAMESALLEN: 'JAMESALLEN',
    //     NO_DIST: 'NO_DIST',
    //   },
    // },
    PARCEL_DIAMONDS: {
      NAME: 'PARCEL_DIAMONDS',
      MODULE: {
        OVERVIEW: 'OVERVIEW',
        SEARCH: 'SEARCH',
        QUICK_SEARCH: 'QUICK_SEARCH',
        COLLECTION: 'COLLECTION',
        SAVED_SEARCH: 'SAVED_SEARCH',
        DEMAND: 'DEMAND',
        ENQUIRY: 'ENQUIRY',
        PRICE_CONFIGURATION: 'PRICE_CONFIGURATION',
      },
    },
    ANALYTICS: {
      NAME: 'ANALYTICS',
    },
    CONFIGURATION: {
      NAME: 'CONFIGURATION',
      MODULE: {
        SYNC_FROM_INDIVIDUAL_INVENTORY: 'SYNC_FROM_INDIVIDUAL_INVENTORY',
        CREATE_COLLECTIONS: 'CREATE_COLLECTIONS',
        TERMS_DETAILS: 'TERMS_DETAILS',
        VOLUME_DISCOUNT: 'VOLUME_DISCOUNT',
        FEATURE_STONE: 'FEATURE_STONE',
        CARAT_RANGE: 'CARAT_RANGE',
        CUT_GROUP: 'CUT_GROUP',
        RAPNET_POLICY: 'RAPNET_POLICY',
        CHECK_IMAGE: 'CHECK_IMAGE',
        CERTIFICATE_UPLOAD: 'CERTIFICATE_UPLOAD',
        DIAMOND_COLUMN_SETTING: 'DIAMOND_COLUMN_SETTING',
        DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',
        QUOTE_DAY: 'QUOTE_DAY',
        CHECK_PACKET: 'CHECK_PACKET',
        CONFIG_MATCHPAIR: 'CONFIG_MATCHPAIR',
        CLIENT_ACTION: 'CLIENT_ACTION',
        MATCH_PAIR_CONFIGURE: 'MATCH_PAIR_CONFIGURE',
        BID_CONFIG: 'BID_CONFIG',
      },
    },
  },
  DASHBOARD: {
    DASHBOARD: {
      NAME: 'DASHBOARD',
    },
    ENQUIRY: {
      NAME: 'ENQUIRY',
    },
    INVENTORY: {
      NAME: 'INVENTORY',
    },
    SEARCH: {
      NAME: 'SEARCH',
    },
    ANALYSIS: {
      NAME: 'ANALYSIS',
      MODULE: {
        NEW_ARRIVAL: 'NEW_ARRIVAL',
        USER_ACTIVITY_ANALYSIS: 'USER_ACTIVITY_ANALYSIS',
        DAILY_VISIT_REPORT: 'DAILY_VISIT_REPORT',
      },
    },
  },
  USER: {
    OVERVIEW: {
      NAME: 'OVERVIEW',
    },
    ACTIONS: {
      NAME: 'ACTIONS',
    },
    PERFORMANCE: {
      NAME: 'PERFORMANCE',
    },
    CONFIGURATION: {
      NAME: 'CONFIGURATION',
      MODULE: {
        CHANGE_PASSOWRD: 'CHANGE_PASSOWRD',
        BASIC_DETAILS: 'BASIC_DETAILS',
        PERMISSIONS: 'PERMISSIONS',
      },
    },
    BEHAVIOUR: {
      NAME: 'BEHAVIOUR',
      MODULE: {
        LOGIN_LOG: 'LOGIN_LOG',
        ACTIVITY: 'ACTIVITY',
      },
    },
  },
  CLIENT: {
    OVERVIEW: {
      NAME: 'OVERVIEW',
    },
    KYC: {
      NAME: 'KYC',
      MODULE: {
        GENERAL: 'GENERAL',
        USER: 'USER',
        BUSINESS: 'BUSINESS_DETAILS',
        BRANCH: 'BRANCH',
        BANKS: 'BANKS',
        REFERENCES: 'REFERENCES',
        SETTING: 'SETTING',
        TERMDETAILS: 'TERMS_DETAIL',
      },
    },
    ENQUIRY: {
      NAME: 'ENQUIRY',
      MODULE: {
        HOLD: 'HOLD',
        MEMO: 'MEMO',
        DEMAND: 'DEMAND',
      },
    },
    TRANSACTION: {
      NAME: 'TRANSACTION',
      MODULE: {
        CART: 'CART',
        WATCHLIST: 'WATCHLIST',
        NOTE: 'NOTES',
        OFFICE_VIEW: 'OFFICE_VIEW',
        SEEN_STONES: 'SEEN_STONES',
        SUGGESTED_STOCK: 'SUGGESTED_STOCK',
        ACCURATE_STOCK: 'ACCURATE_STOCK',
      },
    },
    CONFIRM_STONE: {
      NAME: 'CONFIRM_STONE',
      MODULE: {
        CONFIRM_STONE: 'CONFIRM_STONE',
      },
    },
    OFFER: {
      NAME: 'QUOTE',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
      },
    },
    SEARCH: {
      NAME: 'SEARCH',
      MODULE: {
        SAVEDSEARCH: 'SAVED_SEARCH',
        RECENT: 'RECENT_SEARCH',
        CLIENT_PREFERANCE: 'CLIENT_PREFERANCE',
      },
    },
    ANALYTICS: {
      NAME: 'ANALYTICS',
    },
    SUGGESTED_STOCK: {
      NAME: 'SUGGESTED_STOCK',
    },
  },
  ENQUIRY: {
    CONFIRM: {
      NAME: 'CONFIRM',
    },
    NOTES: {
      NAME: 'NOTES',
    },
    CUSTOMER_HISTORY: {
      NAME: 'CUSTOMER_HISTORY',
    },
    KYC: {
      NAME: 'KYC',
      MODULE: {
        GENERAL: 'GENERAL',
        BUSINESS: 'BUSINESS_DETAILS',
        BRANCH: 'BRANCH',
        BANKS: 'BANKS',
        USER: 'USER',
        REFERENCES: 'REFERENCES',
        SETTING: 'SETTING',
        TERMDETAILS: 'TERMS_DETAIL',
      },
    },
  },
  TODO: {
    KYC: {
      NAME: 'KYC',
      MODULE: {
        CLOSED: 'CLOSED',
        IN_PROGRESS: 'INPROGRESS',
        PENDING: 'PENDING',
      },
    },
    SALES: {
      NAME: 'SALES',
    },
  },
  // CRM: {
  //   TARGET: {
  //     NAME: 'TARGET',
  //   },
  // },
  // UTILITY: {
  //   SHIPPING_LABEL: {
  //     NAME: 'SHIPPING_LABEL',
  //     MODULE: {
  //       PENDING: 'PENDING',
  //       ACCEPTED: 'ACCEPTED',
  //       REJECTED: 'REJECTED',
  //     },
  //   },
  //   CHEQUE_DETAILS: {
  //     NAME: 'CHEQUE_DETAILS',
  //     MODULE: {
  //       PENDING: 'PENDING',
  //       ACCEPTED: 'ACCEPTED',
  //       REJECTED: 'REJECTED',
  //     },
  //   },
  //   RAPNET: {
  //     NAME: 'RAPNET',
  //     MODULE: {
  //       PENDING: 'PENDING',
  //       ACCEPTED: 'ACCEPTED',
  //       REJECTED: 'REJECTED',
  //     },
  //   },
  // },
  SHORTCUT: {
    USER: {
      NAME: 'USER',
    },
    KYC: {
      NAME: 'CLIENT_KYC',
    },
  },
  SETTING: {
    // MASTER: {
    //   NAME: 'MASTER',
    // },
    // SUBMASTER: {
    //   NAME: 'SUB_MASTER',
    // },
    // HOME_SETTING: {
    //   NAME: 'HOME_SETTING',
    // },
    // CONTACT_DIRECTORY: {
    //   NAME: 'CONTACT_DIRECTORY',
    // },
    ACCOUNT: {
      NAME: 'MY_ACCOUNT',
    },
    NOTIFICATION: {
      NAME: 'NOTIFICATION',
    },
    TERMS: {
      NAME: 'TERMS_AND_CONDITIONS',
    },
    MY_SETTING: {
      NAME: 'MY_SETTING',
      MODULE: {
        MASTER: 'MASTER',
        SUB_MASTER: 'SUB_MASTER',
        CAREER: 'CAREER',
        CAREER_APPLICATIONS: 'CAREER_APPLICATIONS',
        FEEDBACK: 'FEEDBACK',
        ROLE_PERMISSION: 'ROLE_PERMISSION',
        HOME_SETTING: 'HOME_SETTING',
        EMAIL_CONFIGURATION: 'EMAIL_CONFIGURATION',
        PROJECT_SETTING: 'PROJECT_SETTING',
        PROMOTIONAL_SETTING: 'PROMOTIONAL_SETTING',
        QUOTE_SETTING: 'QUOTE_SETTING',
        CONTACT_DIRECTORY: 'CONTACT_DIRECTORY',
      },
    },
  },
  ACTIVITIES: {
    TodoList: {
      NAME: 'Todo',
    },
    CallLog: {
      NAME: 'CallLog',
    },
    EMAIL: {
      NAME: 'EMAIL',
    },
    TARGET: {
      NAME: 'TARGET',
    },
    NOTES: {
      NAME: 'NOTES',
      MODULE: {
        DUE: 'DUE',
        UPCOMING: 'UPCOMING',
        ARCHIVED: 'ARCHIVED',
      },
    },
    APPOINTMENT: {
      NAME: 'APPOINTMENT',
      MODULE: {
        PENDING: 'PENDING',
        COMPLETE: 'COMPLETE',
        CANCEL: 'CANCEL',
        REOPEN: 'REOPEN',
      },
    },
  },
};

export const MAINMODULE = {
  TRANSACTION: 'TRANSACTION',
  PRICING: 'PRICING',
  NOTIFICATIONS: 'NOTIFICATION',
  ENQUIRY: 'ENQUIRY',
  INVENTORY: 'INVENTORY',
  DASHBOARD: 'DASHBOARD',
  USER: 'USER',
  CLIENT: 'CLIENT',
  TODO: 'TODO',
  SHORTCUT: 'SHORTCUTS',
  SETTING: 'SETTING',
  BIDSHOW: 'BIDSHOW',
  // ACTIVITIES: 'ACTIVITIES',
  // UTILITY: 'UTILITY',
  //CRM: 'CRM',
};

export function getLoginPermission() {
  let permit = Storage.get('permission') || [];
  if (permit.filter((x) => x.mainModule && x.mainModule).length === 0) permit = [];
  if (!permit.length) permit = cloneDeep(PERMISSION);
  permit.forEach((val) => {
    if (!val.subModule && val.mainModule) {
      const fst = val.mainModule.split('_')[0];
      const main = invert(MAINMODULE)[val.mainModule] ? val.mainModule : invert(MAINMODULE)[fst] ? fst : val.mainModule;
      const mod = val.module;
      const sub = val.subModule
        ? val.subModule
        : invert(MAINMODULE)[fst] && val.mainModule.includes('_')
        ? val.mainModule.split('_').slice(1).join('_')
        : val.module;
      // if (val.subModule === 'SINGLE_STONE') console.log(val);
      // console.log(main, ' ', sub, ' ', mod);
      // val.permissions = find(PERMISSION,{mainModule:main,module:mod,sub:sub})
      val.mainModule = main;
      val.subModule = sub;
      const obj = PERMISSION.filter((el) => el.mainModule === main && el.subModule === sub && el.module === mod);
      if (obj && obj[0]) {
        val.url = obj[0].url;
      }
    }
  });

  // return PERMISSION;
  return permit;
}

export function getPermittedMainModuleList() {
  const PermissionData = getLoginPermission();
  let MenuData = [];
  if (PermissionData) {
    MENU.forEach((val) => {
      const modules = PermissionData.filter((el) => el.mainModule === val.permit)
        .map((x) => x.permissions.view || x.permissions.all)
        .filter((el) => el && el);
      if (modules.length) MenuData.push(val);
    });
  } else MenuData = MENU;
  return MenuData;
}

export function getPermittedSubModuleList(mainModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.subModule || x.module);
  return MenuData;
}

export function getPermittedModuleList(mainModule, subModule) {
  const PermissionData = getLoginPermission();

  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedModuleListWithoutSubmodule(mainModule, module) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.module === module)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedInsertList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.insert || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedInsertListFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = subModule
    ? find(PermissionData, { mainModule, subModule })
    : find(PermissionData, { mainModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.insert || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedUpdateListFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = find(PermissionData, { mainModule, subModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.update || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedDeleteFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = subModule
    ? find(PermissionData, { mainModule, subModule })
    : find(PermissionData, { mainModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.delete || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else MenuData = [];
  return MenuData;
}

export function getPermittedExportList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.downloadExcel || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function permiableRoute(url) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url === url)
    .map((x) => x.permissions.view || x.permissions.all)
    .filter((el) => el && el);
  return MenuData.length ? true : false;
}

export const filterRoutesByModule = (routesList, module, subModule) => {
  if (!module || !isArray(routesList)) return routesList;

  const PermittedSubModuleList = subModule
    ? getPermittedModuleList(module, subModule)
    : getPermittedSubModuleList(module);

  if (isEmpty(PermittedSubModuleList) || !isArray(routesList)) return routesList;
  return routesList.filter((el) => PermittedSubModuleList.includes(el?.tab));
};

export function getModuleFromUrl() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url));
  const permit = MenuData[0] ? MenuData[0].permissions : {};
  if (permit.all) {
    // permit = {
    //   view: true,
    //   insert: true,
    //   update: true,
    //   delete: true,
    //   uploadExcel: true,
    //   downloadExcel: true,
    //   mailExcel: true,
    //   printPDF: true,
    //   all: true,
    // };
    Object.keys(permit).map((key) => {
      permit[key] = true;
    });
  }
  return permit;
}

export function downloadPermit() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url))
    .map((x) => x.permissions.downloadExcel || x.permissions.all)
    .filter((el) => el && el);
  return MenuData[0] ? true : false;
}

export function printPermit() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url))
    .map((x) => x.permissions.printPDF || x.permissions.all)
    .filter((el) => el && el);
  return MenuData[0] ? true : false;
}

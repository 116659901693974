export class InvalidArgumentError extends Error {
  constructor({ pos = '0', name = 'argument', types = ['valid'] }) {
    super(
      `\`${name}\` must be of type(s) - (${types
        ?.map?.((type) => (type?.constructor?.name === 'String' ? type : type?.name))
        ?.join?.(', ')}); \`${typeof iteratee}\` passed at position - ${pos}.`,
    );
    this.name = 'Invalid Argument';
    this.code = 'INVALID_ARGUMENT';
  }
}

import { generatePath } from 'react-router-dom';

import forOwn from 'lodash/forOwn';
import isEqual from 'lodash/isEqual';
import memoize from 'lodash/memoize';
import xorWith from 'lodash/xorWith';
import { DateTime, Duration } from 'luxon';

import { clone } from 'util/clone';
import { InvalidArgumentError } from 'util/errors';
import * as REGEX from 'util/regex';

import { LOCALE, CURRENCY, FRACTION_LENGTH, TIMEZONE_IANA, LUXON_FORMAT } from 'constants/Common';

export { clone } from 'util/clone';

export function runInDevelopment(callback) {
  return [undefined, '', 'development'].includes(process.env.REACT_APP_ENV) && callback();
}

export function logInfo(...args) {
  return runInDevelopment(() => console.info(...args)); // eslint-disable-line no-console
}

export function logWarn(...args) {
  return runInDevelopment(() => console.warn(...args)); // eslint-disable-line no-console
}

export function logError(...args) {
  return runInDevelopment(() => console.error(...args)); // eslint-disable-line no-console
}

export function logTable(...args) {
  return runInDevelopment(() => console.table(...args)); // eslint-disable-line no-console
}

export function catchError(func, onError) {
  const handleError = (error) => {
    logWarn(error);
    return onError?.(error);
  };
  try {
    const output = func?.();
    if (output?.constructor?.name !== 'Promise') return output;
    if (output?.catch?.constructor?.name !== 'Function') return output;
    return output?.catch?.(handleError);
  } catch (error) {
    return handleError(error);
  }
}

export function yieldToMain() {
  return new Promise((resolve) => setTimeout(resolve, 0));
}

export async function runMicroTasks(tasks) {
  const results = [];
  tasks = [...tasks];

  while (tasks.length > 0) {
    if (navigator?.scheduling?.isInputPending?.()) {
      await yieldToMain();
    } else {
      const task = tasks.shift();
      results.push(catchError(task));
    }
  }

  return results;
}

export function sleep(ms = 100) {
  return new Promise((r) => setTimeout(r, ms));
}

export function typeOf(input, type) {
  return input?.constructor?.name === (type ?? null);
}

export function isArray(input) {
  return typeOf(input, 'Array');
}

export function isObject(input) {
  return typeOf(input, 'Object');
}

export function isBoolean(input) {
  return typeOf(input, 'Boolean');
}

export function isString(input) {
  return typeOf(input, 'String');
}

export function isNumber(input) {
  return typeOf(input, 'Number') && !Number.isNaN(input) && Number.isFinite(input);
}

export function isFunction(input) {
  return typeOf(input, 'Function');
}

export function isAlphaNumeric(input, strict = false) {
  return new RegExp(strict ? REGEX.ALPHA_NUMERIC.STRICT : REGEX.ALPHA_NUMERIC.LOOSE).test(input);
}

export function isNumeric(input, strict = false) {
  return new RegExp(strict ? REGEX.NUMERIC.STRICT : REGEX.NUMERIC.LOOSE).test(input);
}

export function isIterable(input) {
  return isFunction(input?.[Symbol.iterator]);
}

export function isHTMLElement(input) {
  return input instanceof HTMLElement;
}

export function isEmpty(input, options) {
  options = { isEmpty: [], isNotEmpty: [], ...options };

  if (options.isEmpty?.includes?.(input)) return true;
  if (options.isNotEmpty?.includes?.(input)) return false;
  if ([undefined, null].includes(input)) return true;

  if (input?.constructor?.name === 'Array') return !input.length;
  if (input?.constructor?.name === 'Number') return Number.isNaN(input);
  if (input?.constructor?.name === 'Object') return !Object.keys(input).length;
  if (input?.constructor?.name === 'String') return !input.trim().length;

  return false;
}

export function isNotEmpty(...args) {
  return !isEmpty(...args);
}

export function pruneEmpty(input, options) {
  options = { clone: false, ...options };
  input = options.clone ? clone(input) : input;

  const prune = (current) => {
    current = (() => {
      catchError(() => {
        if (isEmpty(current)) return undefined;
        if (isString(current)) return current?.trim?.();
        if (isArray(current)) return current.filter((value) => !isEmpty(prune(value), options));
        forOwn(current, (value, key) => {
          if (isEmpty(value, options) || ((isObject(value) || isArray(value)) && isEmpty(prune(value), options)))
            delete current[key];
        });
      });
      return current;
    })();
    current = !isEmpty(current, options) ? current : undefined;
    return current;
  };
  return prune(input);
}

export function get(input, path = '', defaultValue = undefined) {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), input);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === input ? defaultValue : result;
}

export function getKey(input, pathGetter) {
  return isFunction(pathGetter) ? pathGetter(input) : get(input, pathGetter);
}

export function hasKey(object, key) {
  return isObject(object) && !isEmpty(object) && Object.keys(object).includes(key);
}

export function withDefaults(object, defaults) {
  return { ...defaults, ...object };
}

export function keys(...args) {
  return catchError(() => Object.keys(...args)) ?? [];
}

export function values(...args) {
  return catchError(() => Object.values(...args)) ?? [];
}

export function forEach(instance, iteratee) {
  if (!isFunction(iteratee) && !isString(iteratee)) {
    throw new InvalidArgumentError({ arg: iteratee, name: 'iteratee', pos: 2, types: [Function, String] });
  }

  const callback = isString(iteratee) ? (obj) => get(obj, iteratee) : iteratee;
  return catchError(
    () => Array.prototype.forEach.call(instance, callback),
    () => [],
  );
}

export function map(instance, iteratee) {
  if (!isFunction(iteratee) && !isString(iteratee)) {
    throw new InvalidArgumentError({ arg: iteratee, name: 'iteratee', pos: 2, types: [Function, String] });
  }

  const callback = isString(iteratee) ? (obj) => get(obj, iteratee) : iteratee;
  return catchError(
    () => Array.prototype.map.call(instance, callback),
    () => [],
  );
}

export function sort(list = [], desc = false) {
  const returnValue = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return list.sort((curr, next) => {
    return curr < next ? returnValue.less : curr > next ? returnValue.more : 0;
  });
}

export function sortBy(list = [], pathGetter, desc = false) {
  const returnValue = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return list.sort((curr, next) => {
    const currVal = getKey(curr, pathGetter);
    const nextVal = getKey(next, pathGetter);
    return currVal < nextVal ? returnValue.less : currVal > nextVal ? returnValue.more : 0;
  });
}

export function unique(list = []) {
  return list.reduce((p = [], c) => {
    const indexFound = p.findIndex((item) => item === c);
    if (indexFound === -1) p.push(c);
    return p;
  }, []);
}

export function uniqueBy(list = [], pathGetter) {
  const output = list.reduce((p = [], c) => {
    const currentKey = getKey(c, pathGetter);
    const indexFound = p.findIndex((item) => getKey(item, pathGetter) === currentKey);
    if (indexFound === -1) p.push(c);
    return p;
  }, []);
  return output;
}

export function groupBy(list = [], pathGetter) {
  const output = {};
  list.forEach((item) => {
    const key = getKey(item, pathGetter);
    output[key] = output[key] ?? [];
    output[key].push(item);
  });
  return output;
}

export function returnIf(func, ...args) {
  return func(...args) ? args?.[0] : undefined;
}

export function returnIfNotEmpty(input, defaultValue) {
  return returnIf(isNotEmpty, input) ?? defaultValue;
}

export function isArrayEqual(list1, list2) {
  return isEmpty(xorWith(list1, list2, isEqual));
}

export function getCurrentTime() {
  return DateTime.local().setZone(TIMEZONE_IANA);
}

export function formatDateTime(isoDateTime, format = LUXON_FORMAT.DATE_TIME) {
  const dateTime = DateTime.fromISO(isoDateTime);
  return dateTime.isValid ? dateTime.toFormat(format) : undefined;
}

export function formatDate(isoDateTime, format = LUXON_FORMAT.DATE) {
  return formatDateTime(isoDateTime, format);
}

export function formatTime(isoDateTime, format = LUXON_FORMAT.TIME) {
  return formatDateTime(isoDateTime, format);
}

export function formatDuration(duration, format = LUXON_FORMAT.DURATION) {
  return Duration.isDuration(duration) && duration?.isValid ? duration.toFormat(format) : undefined;
}

export function getDateTimeDiff(startISO, endISO) {
  return DateTime.fromISO(endISO).diff(DateTime.fromISO(startISO));
}

export function getFormattedDateTimeDiff(startISO, endISO, format = LUXON_FORMAT.DURATION) {
  return formatDuration(getDateTimeDiff(startISO, endISO), format);
}

export function castToNumber(input, altOutput = undefined) {
  const output = isString(input) ? Number(`${input}`.replace(/[^0-9.+-]/g, '')) : Number(input);
  return isNumber(output) ? output : altOutput;
}

export function formatNumber(input, options = {}) {
  if (isNumber(options)) options = { fractionLength: options };

  input = castToNumber(input);
  if (!isNumber(input)) return undefined;

  const { locale, fractionLength, ...rest } = { locale: LOCALE, ...options };
  const fractionDigits = fractionLength ?? `${input}`.split('.')?.[1]?.length ?? 0;
  const formatOptions = { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits, ...rest };
  return new Intl.NumberFormat(locale, formatOptions).format(input);
}

export function formatCurrency(input, options = {}) {
  if (isNumber(options)) options = { fractionLength: options };
  return formatNumber(input, { style: 'currency', currency: CURRENCY, fractionLength: FRACTION_LENGTH, ...options });
}

export function formatDecimal(input, options = {}) {
  if (isNumber(options)) options = { fractionLength: options };
  return formatNumber(input, { fractionLength: FRACTION_LENGTH, ...options });
}

export function parseDecimal(input, fractionLength = FRACTION_LENGTH) {
  if (!isNumber(Number(input))) return undefined;
  return Number(parseFloat(input).toFixed(fractionLength));
}

export function formatFloat(input, fractionLength = FRACTION_LENGTH) {
  if (!isNumber(Number(input))) return undefined;
  return parseFloat(input).toFixed(fractionLength);
}

export function sortArrayByKey(key = 'id', desc = false) {
  if (!isString(key)) return undefined;
  const n = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return (curr, next) => (curr?.[key] < next?.[key] ? n.less : curr?.[key] > next?.[key] ? n.more : 0);
}

export function reduceUnique(key) {
  return (a = [], c) => {
    const indexFound = a.findIndex((item) => (key === undefined ? item === c : item[key] === c[key]));
    if (indexFound === -1) a.push(c);
    return a;
  };
}

export function padArray(input, length, fillWith) {
  return input.concat(Array(length).fill(fillWith)).slice(0, length);
}

export function formatInlineList(list, options = {}) {
  options = { separator: ',', returnString: true, removeDupes: true, allowAppend: false, ...options };

  if (isArray(list)) list = list.join(options.separator);
  if (!isString(list)) return list;

  let output = `${list}`.replace(/[\s,]+/gm, options.separator).split(options.separator);
  output = output.filter(
    (value, index) => !isEmpty(value) || (options.allowAppend && index && output?.length === index + 1),
  );
  options.removeDupes = options.allowAppend
    ? isEmpty(output[output.length - 1]) && options.removeDupes
    : options.removeDupes;
  output = options.removeDupes ? unique(output) : output;
  output = options.returnString ? output.join(options.separator) : output;

  return output;
}

export function reduceTotal(list, key) {
  if (!isArray(list) || isEmpty(list)) return 0;
  const numList = key === undefined ? list.map(Number) : list.map((item) => Number(item?.[key]));
  return numList.filter(isNumber).reduce((pv, cv) => (pv += cv), 0);
}

export function classNames(list) {
  return list.filter(isString).join(' ');
}

export function upperFirst(input, locale = LOCALE) {
  if (!isString(input)) return '';
  return input.replace(/(^[a-z])/, (match) => match.toLocaleUpperCase(locale));
}

export function lowerFirst(input, locale = LOCALE) {
  if (!isString(input)) return '';
  return input.replace(/(^[a-z])/, (match) => match.toLocaleLowerCase(locale));
}

export function upperCase(input, locale = LOCALE) {
  if (!isString(input)) return '';
  return input.toLocaleUpperCase(locale);
}

export function lowerCase(input, locale = LOCALE) {
  if (!isString(input)) return '';
  return input.toLocaleLowerCase(locale);
}

export function titleCase(input, locale = LOCALE) {
  if (!isString(input)) return '';
  const list = input.split(/([ :–—-])/);
  const words = list.map((current, index, list) => {
    return (
      // Check for small words
      current.search(/^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i) > -1 &&
        // Skip first and last word
        index !== 0 &&
        index !== list.length - 1 &&
        // Ignore title end and subtitle start
        list[index - 3] !== ':' &&
        list[index + 1] !== ':' &&
        // Ignore small words that start a hyphenated phrase
        (list[index + 1] !== '-' || (list[index - 1] === '-' && list[index + 1] === '-'))
        ? current.toLocaleLowerCase(locale)
        : current.substr(1).search(/[A-Z]|\../) > -1 // Ignore intentional capitalization
        ? current
        : list[index + 1] === ':' && list[index + 2] !== '' // Ignore URLs
        ? current
        : current.replace(/([A-Za-z0-9\u00C0-\u00FF])/, (match) => match.toLocaleUpperCase(locale)) // Capitalize the first letter
    );
  });
  return words.join('');
}

export function objectToQueryString(object) {
  return catchError(
    () =>
      `?${Object.entries(object)
        .map(([key, value]) => `${key}=${!isEmpty(value) && isFunction(value?.toString) ? value.toString() : ''}`)
        .join('&')}`,
    () => '',
  );
}

export function queryStringToObject(search = window.location.search) {
  return catchError(
    () => {
      const urlParams = new URLSearchParams(search);
      return Object.fromEntries(urlParams.entries());
    },
    () => {},
  );
}

export function generateRoutePath(path, params) {
  return catchError(
    () => generatePath(path, params),
    () => path.split(':')?.[0] ?? '',
  );
}

export const getUserName = memoize(
  (input, replace = '-') => {
    if (hasKey(input, 'user')) input = input?.user;
    const name = [input?.firstName, input?.lastName].filter(isNotEmpty);
    return titleCase(!isEmpty(name) ? name.join(' ') : input?.name ?? input?.username ?? replace);
  },
  (user, replace) => `${user?.firstName}${user?.lastName}${user?.name}${user?.username}${replace}`,
);

export const stopDOMEvent = (event) => {
  void event?.preventDefault?.();
  void event?.stopPropagation?.();
};

export const getFormattedUrl = (input) => {
  if (!isString(input)) return '';
  if (input.startsWith('https://') || input.startsWith('http://') || input.startsWith('//')) {
    return input.toLowerCase();
  }
  return `//${input.toLowerCase()}`;
};

export const capitalize = (input) =>
  input?.replace(/_/g, ' ')?.replace(/(\w+)/g, (x) => x[0].toUpperCase() + x.substring(1));

export const randomString = (length = 50) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getImagePath = (fileName) => `https://s3.ap-south-1.amazonaws.com/finestargroup/RealImages/${fileName}`;

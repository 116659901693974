import ConstantApi from 'constants/CommonApi';
import UtilService from 'services/util';
import { isEmpty } from 'util/utils';

export const getTermsDetailList = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.TermsDetail.paginate, request, ...(!isEmpty(config) && { config }) });

export const storeTermsDetail = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.TermsDetail.add, request, ...(!isEmpty(config) && { config }) });

export const findTermsDetail = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.TermsDetail.view, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const approveTermsDetail = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.TermsDetail.approve, request, ...(!isEmpty(config) && { config }) });

export const updateTermsDetail = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.TermsDetail.update, request, ...(!isEmpty(config) && { config }) });

export const deleteTermsDetail = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.TermsDetail.delete, request, ...(!isEmpty(config) && { config }) });

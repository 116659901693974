import UtilService from 'services/util';
import { isEmpty } from 'util/utils';

export const API_ROUTES = {
  list: { method: 'post', url: '/admin/v1/address/paginate1' },
  create: { method: 'post', url: '/admin/v1/address/create' },
  read: { method: 'get', url: '/admin/v1/address/' },
  update: { method: 'post', url: '/admin/v1/address/' },
  upsert: { method: 'post', url: '/admin/v1/address/upsert' },
  destroy: { method: 'post', url: '/admin/v1/address/destroy' },
};

export const listAccountAddress = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.list, request, ...(!isEmpty(config) && { config }) });

export const getAccountAddress = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.read, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const addAccountAddress = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.create, request, ...(!isEmpty(config) && { config }) });

export const updateAccountAddress = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.update, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const upsertAccountAddress = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.upsert, request, ...(!isEmpty(config) && { config }) });

export const deleteAccountAddress = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.destroy, request, ...(!isEmpty(config) && { config }) });

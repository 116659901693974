import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { formatNumber, isEmpty } from 'util/utils';

import { DiamondActions } from 'appRedux/reducers/diamond';

export { formatNumber } from 'util/utils';

export function getExtraPer(terms) {
  return terms && terms.addPer ? terms.addPer : 0;
}

export const calcSystemDiscount = (amt) => {
  if (!Number(amt)) return 0;
  amt = Number(amt);
  const MIN_PER = 0.00004;
  const MAX_PER = 0.00001;
  const END_PER = 5.0;

  const MIN_AMT = 99999.0;
  const MAX_AMT = 199999.0;
  const END_AMT = 1000000.0;

  if (Number(amt) <= MIN_AMT) {
    return Number(amt) * MIN_PER;
  }
  if (Number(amt) <= MAX_AMT) {
    const upperAmt = Number(amt) - MIN_AMT;
    return upperAmt * MAX_PER + MIN_AMT * MIN_PER;
  }
  if (Number(amt) > MAX_AMT && Number(amt) <= END_AMT) {
    return END_PER;
  }
  return 0;
};

export const SELECT_STONE_TERMS_NEW = [
  { id: '0', value: -2, name: 'COD EXPORT' },
  { id: '1', value: -1, name: '30 DAYS EXPORT' },
  { id: '2', value: -1, name: 'COD RS/DD BILL' },
  { id: '3', value: -1, name: 'HK DELIVERY' },
  { id: '4', value: 0, name: '30 DAYS COD RS/DD BILL' },
];

export function calculate(dataList = [], term) {
  const sum_total = {
    total_pieces: 0,
    total_carat: 0,
    total_avg_rap: 0,
    final_discount: 0,
    final_price: 0,
    final_value: 0,
    total_rapaport: 0,
    avg_discount: 0,
    final_net_value: 0,
    final_rate: 0,
    final_term_discount: 0,
    avg_term_discount: 0,
  };
  const total = {
    fancy_total: { ...sum_total },
    non_fancy_total: { ...sum_total },
  };
  total.fancy_total.isFancy = 1;
  total.non_fancy_total.isFancy = 0;
  dataList.forEach((lst) => {
    const use_total_key = lst.isFancy ? 'fancy_total' : 'non_fancy_total';
    total[use_total_key].total_pieces += 1;
    total[use_total_key].total_carat += lst.crt && parseFloat(lst.crt) ? parseFloat(lst.crt) : 0;
    lst.rap_avg = lst.rap ? parseFloat(lst.rap) * parseFloat(lst.crt || 0) : 0;
    total[use_total_key].total_avg_rap += lst.rap_avg;
    total[use_total_key].total_rapaport += lst.rap ? parseFloat(lst.rap) : 0;
    total[use_total_key].final_value += lst.amt || 0;
    total[use_total_key].final_net_value += lst.calcAmount || 0;
  });
  total.fancy_total.final_price =
    total.fancy_total.final_value && total.fancy_total.total_carat
      ? total.fancy_total.final_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_price =
    total.non_fancy_total.final_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.final_rate =
    total.fancy_total.final_net_value && total.fancy_total.total_carat
      ? total.fancy_total.final_net_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_rate =
    total.non_fancy_total.final_net_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_net_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.total_avg_rap = total.fancy_total.total_avg_rap
    ? total.fancy_total.total_avg_rap / total.fancy_total.total_carat
    : 0;
  total.non_fancy_total.total_avg_rap = total.non_fancy_total.total_avg_rap
    ? total.non_fancy_total.total_avg_rap / total.non_fancy_total.total_carat
    : 0;

  total.fancy_total.final_discount =
    total.fancy_total.final_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_price / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_discount =
    total.non_fancy_total.final_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  total.fancy_total.final_term_discount =
    total.fancy_total.final_rate && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_rate / total.fancy_total.total_avg_rap) * -100 * -1
      : 0;
  total.non_fancy_total.final_term_discount =
    total.non_fancy_total.final_rate && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_rate / total.non_fancy_total.total_avg_rap) * -100 * -1
      : 0;

  const allTotal = {
    total_pieces: total.fancy_total.total_pieces + total.non_fancy_total.total_pieces,
    total_carat: total.fancy_total.total_carat + total.non_fancy_total.total_carat,
    final_rapaport: total.fancy_total.total_avg_rap + total.non_fancy_total.total_avg_rap,
    final_discount: total.fancy_total.final_discount
      ? total.fancy_total.final_discount
      : total.non_fancy_total.final_discount,
    final_value: total.fancy_total.final_value + total.non_fancy_total.final_value,
    final_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_value + total.non_fancy_total.final_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    total_rapaport: total.fancy_total.total_rapaport + total.non_fancy_total.total_rapaport,
    final_net_value: total.fancy_total.final_net_value + total.non_fancy_total.final_net_value,
    final_rate:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_net_value + total.non_fancy_total.final_net_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_term_discount: total.fancy_total.final_term_discount
      ? total.fancy_total.final_term_discount
      : total.non_fancy_total.final_term_discount,
    amt_dis: calcSystemDiscount(total.fancy_total.final_value + total.non_fancy_total.final_value) * -1,
    term_dis: term && term.term,
    sys_dis:
      (total.fancy_total.final_value + total.non_fancy_total.final_value) *
      ((calcSystemDiscount(total.fancy_total.final_value + total.non_fancy_total.final_value) * -1) / 100),
    after_amt_dis:
      total.fancy_total.final_value +
      total.non_fancy_total.final_value +
      (total.fancy_total.final_value + total.non_fancy_total.final_value) *
        ((calcSystemDiscount(total.fancy_total.final_value + total.non_fancy_total.final_value) * -1) / 100),
  };
  allTotal.avg_discount = allTotal.final_rapaport ? allTotal.final_price / allTotal.final_rapaport : 0;
  allTotal.avg_term_discount = allTotal.final_rapaport ? allTotal.final_rate / allTotal.final_rapaport : 0;
  return allTotal;
}
export function newDiamondPrice(diamond, terms = {}, qt = false) {
  const extraPer = terms && terms.addPer ? terms.addPer : 0;
  // if (!extraPer) {
  //   ['pricePerCarat', 'calcPricePerCarat', 'calcDiscount', 'calcAmount'].forEach((key) => delete diamond[key]);
  // }
  // const rapPer = 0 //terms && terms.rapPer ? terms.rapPer : 0
  const quotePer = qt && diamond.finalquote ? Number(parseFloat(diamond.finalquote * -1).toFixed(2)) : 0;
  // if (extraPer || quotePer) {
  const pricePerCarat = ['bid'].includes(qt)
    ? diamond.bidPricePerCarat
    : quotePer
    ? Number(parseFloat(diamond.rap - (diamond.rap * quotePer) / 100).toFixed(2))
    : diamond.ctPr;

  diamond.calcPricePerCarat = Number(parseFloat(pricePerCarat - (pricePerCarat * Math.abs(extraPer)) / 100).toFixed(2));
  diamond.calcDiscount = Number(parseFloat((1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1).toFixed(2));
  diamond.calcAmount = Number(parseFloat(diamond.calcPricePerCarat * diamond.crt).toFixed(2));
  // } else {
  //   let pricePerCarat = diamond.ctPr;
  //   diamond.calcPricePerCarat = Number(parseFloat(pricePerCarat).toFixed(2));
  //   diamond.calcDiscount = Number(parseFloat((1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1).toFixed(2));
  //   diamond.calcAmount = Number(parseFloat(diamond.calcPricePerCarat * diamond.crt).toFixed(2));
  // }
  return diamond;
}
export const quoteCalculation = (diamondList) => {
  const newDiamond = {
    oldDiscount: 0,
    newDiscount: 0,
    offerValue: 0,
  };
  diamondList.forEach((data) => {
    newDiamond.oldDiscount += data.back * data.crt;
    newDiamond.newDiscount += data.newDiscount * data.crt;
    newDiamond.offerValue += data.newAmount * data.crt;
  });

  const { total_carat } = calculate(diamondList);
  newDiamond.oldDiscount = newDiamond.oldDiscount / total_carat;
  newDiamond.newDiscount = newDiamond.newDiscount / total_carat;
  newDiamond.offer = newDiamond.newDiscount;
  newDiamond.offerValue = newDiamond.offerValue / total_carat;
  newDiamond.discountDifferent = newDiamond.newDiscount - newDiamond.oldDiscount;
  return newDiamond;
};

const SelectStone = ({ sum, currentType }, props) => {
  const dispatch = useDispatch();
  const [term, setTerm] = React.useState('0');
  const clearAll = React.useCallback(() => dispatch(DiamondActions.resetSelectedRows()), [dispatch]);
  const checked = useSelector((state) => state?.diamondData?.selectedRows?.[currentType] || []);
  const show = localStorage.getItem('companyName');
  const total = React.useMemo(
    () => calculate(checked, { term: SELECT_STONE_TERMS_NEW.find(({ id }) => id === term)?.value ?? 0 }),
    [checked, term],
  );
  const final_term_amt = total.after_amt_dis + total.after_amt_dis * (total.term_dis / 100);
  const final_term_ctPr = final_term_amt / total.total_carat;
  const final_term_disc = (1 - final_term_ctPr / total?.final_rapaport) * -100;
  let calcList = [];

  // if (show === 'false') {
  calcList = [
    { label: 'Pieces', check: total.total_pieces || 0, sum: sum?.pieces || sum?.count || 0 },
    {
      label: 'Cts',
      check: parseFloat(total?.total_carat || 0).toFixed(2),
      // sum: parseFloat(sum?.totalCarat || 0).toFixed(2),
    },
    {
      label: 'Rap Price',
      check: parseFloat(total?.final_rapaport || 0).toFixed(2),
      // sum: parseFloat(sum?.rapAvg || 0).toFixed(2),
    },
    {
      label: 'Avg Disc(%)',
      check: parseFloat(total?.final_discount || 0).toFixed(2),
      // sum: parseFloat(sum?.avgDiscount || 0).toFixed(2),
    },
    {
      label: 'Total Ct/Pr',
      check: parseFloat(total?.final_price || 0).toFixed(2),
      // sum: Math.round(sum?.totalPricePerCarat || 0),
    },
    {
      label: 'Amount',
      check: parseFloat(total?.final_value || 0).toFixed(2),
      // sum: Math.round(sum?.totalAmount || 0),
    },
  ];
  // } else {
  //   calcList = [
  //     { label: 'Pieces', check: total.total_pieces || 0, sum: sum?.pieces || sum?.count || 0 },
  //     {
  //       label: 'Cts',
  //       check: parseFloat(total?.total_carat || 0).toFixed(2),
  //       // sum: parseFloat(sum?.totalCarat || 0).toFixed(2),
  //     },
  //     {
  //       label: 'Rap Price',
  //       check: parseFloat(total?.final_rapaport || 0).toFixed(2),
  //       // sum: parseFloat(sum?.rapAvg || 0).toFixed(2),
  //     },
  //     {
  //       label: 'Avg Disc(%)',
  //       check: parseFloat(total?.final_discount || 0).toFixed(2),
  //       // sum: parseFloat(sum?.avgDiscount || 0).toFixed(2),
  //     },
  //     {
  //       label: 'Total Ct/Pr',
  //       check: parseFloat(total?.final_price || 0).toFixed(2),
  //       // sum: Math.round(sum?.totalPricePerCarat || 0),
  //     },
  //     {
  //       label: 'Amount',
  //       check: parseFloat(total?.final_value || 0).toFixed(2),
  //       // sum: Math.round(sum?.totalAmount || 0),
  //     },
  // {
  //   label: 'Amt Disc(%)',
  //   check: parseFloat(total?.amt_dis || 0).toFixed(2),
  //   // sum: Math.round(sum?.totalAmount || 0),
  // },
  // {
  //   label: 'term Disc(%)',
  //   check: parseFloat(total?.term_dis || 0).toFixed(2),
  //   // sum: Math.round(sum?.totalAmount || 0),
  // },
  // {
  //   label: 'Final Disc(%)',
  //   check: parseFloat(final_term_disc || 0).toFixed(2),
  //   // sum: Math.round(sum?.totalAmount || 0),
  // },
  // {
  //   label: 'Final Pr/ct',
  //   check: parseFloat(final_term_ctPr || 0).toFixed(2),
  //   // sum: Math.round(sum?.totalAmount || 0),
  // },
  // {
  //   label: 'Final Amount',
  //   check: parseFloat(final_term_amt || 0).toFixed(2),
  //   // sum: Math.round(sum?.totalAmount || 0),
  // },
  // ];
  //}

  return (
    !isEmpty(checked) && (
      <div className="selectStoneValueBlock">
        {calcList.map((item, index) => {
          return (
            <div className="selectStoneValueItem" key={index}>
              <span className="selectStoneLabel">{item.label} : </span>
              <span className="selectStopnValue">
                <span className="redColor">
                  {item.label === 'Avg Disc(%)' || item.label === 'Final Disc(%)'
                    ? item.check > 0
                      ? `+${item.check}`
                      : formatNumber(item.check)
                    : formatNumber(item.check)}
                </span>
              </span>
            </div>
          );
        })}
        {show === 'true' && (
          <div className="selectStoneValueItem">
            <span className="selectStoneLabel">TERMS : </span>
            <select name="term" value={term} placeholder="Select Terms" onChange={(e) => setTerm(e.target.value)}>
              {SELECT_STONE_TERMS_NEW.map((term) => (
                <option key={term?.id} value={term?.id}>
                  {term?.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="searchStoneClose" onClick={clearAll}>
          Clear all
        </div>
      </div>
    )
  );
};

export default SelectStone;

export const LOGIN_MASTERS_LIST = [
  'COLOR',
  'CLARITY',
  'SHAPE',
  'CUT',
  'FLUORESCENCE',
  'SHADE',
  'LAB',
  'POLISH',
  'SYMMETRY',
  'LOCATION',
  'BLACK_INCLUSION',
  'OPEN_INCLUSION_TABLE',
  'MILKEY',
  'WHITE_INCLUSION_CROWN',
  'OPEN_INCLUSION_CROWN',
  'EYECLEAN',
  'OPEN_INCLUSION_PAVILION',
  'ORIGIN',
  'BLACK_INCLUSION_CROWN',
  'H_AND_A',
  'WHITE_INCLUSION_TABLE',
  'FANCY_COLOR',
  'INTENSITY',
  'OVERTONE',
  'KEY_TO_SYMBOLS',
  'GIRDLE',
  'CULET',
  'GIRDLE_COND',
  'CULET_COND',
  'COMPANY_SIZE',
  'BLACK_INCLUSION_TABLE',
  'BLACK_INCLUSION_SIDE',
  'WHITE_INCLUSION_SIDE',
  'BRILLIANCY',
  'EYECLEAN',
  'DAY_TERM',
  'CURRENCY',
  'COMPANY_GROUP',
  'BLOCK_STAGE',
  'NATURE_OF_ORG',
  'BUSINESS_TYPE',
  'PROFORMA_INSTRUCTION_TYPES',
  'DOC_TYPE_PERSONAL',
  'DOC_TYPE_BUSINESS',
  'USER_ROLES',
  'LUSTER',
  'GRAINING',
  'EXTRA_FACET_CROWN',
  'EXTRA_FACET_PAVILION'
];

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

// eslint-disable-next-line import/no-cycle
import { MAINMODULE } from './permission';

export const MENU = [
  // {
  //   id: 60,
  //   path: `${PREFIX_URL_WITH_SLASH}/dashboard`,
  //   name: 'Home',
  //   svg: 'Dashboard',
  //   permit: MAINMODULE.DASHBOARD,
  // },
  {
    id: 71,
    path: `${PREFIX_URL_WITH_SLASH}/inventory`,
    name: 'Inventory',
    svg: 'Master',
    permit: MAINMODULE.INVENTORY,
  },
  {
    path: `${PREFIX_URL_WITH_SLASH}/client`,
    name: 'Clients',
    svg: 'Account',
    id: 72,
    permit: MAINMODULE.CLIENT,
  },
  // {
  //   path: `${PREFIX_URL_WITH_SLASH}/enquiry`,
  //   name: 'Enquiry',
  //   svg: 'Enquiry',
  //   id: 75,
  //   permit: MAINMODULE.ENQUIRY,
  // },
  {
    path: `${PREFIX_URL_WITH_SLASH}/transaction`,
    name: 'Transactions',
    svg: 'Transactions',
    id: 76,
    permit: MAINMODULE.TRANSACTION,
  },
  {
    path: `${PREFIX_URL_WITH_SLASH}/pricing`,
    name: 'Pricing',
    svg: 'Pricing',
    id: 77,
    permit: MAINMODULE.PRICING,
  },
  {
    path: `${PREFIX_URL_WITH_SLASH}/notification`,
    name: 'Notifications',
    svg: 'Notification',
    id: 78,
    permit: MAINMODULE.NOTIFICATIONS,
  },
  {
    path: `${PREFIX_URL_WITH_SLASH}/activity`,
    name: 'Activity',
    svg: 'Todo',
    id: 79,
    permit: MAINMODULE.ACTIVITIES,
  },
  {
    path: `${PREFIX_URL_WITH_SLASH}/bid`,
    name: 'Bid',
    svg: 'Bid',
    id: 82,
    permit: MAINMODULE.BIDSHOW,
  },
  // {
  //   path: `${PREFIX_URL_WITH_SLASH}/todo`,
  //   name: 'Todo',
  //   svg: 'Todo',
  //   id: 79,
  //   permit: MAINMODULE.TODO,
  // },
  {
    path: `${PREFIX_URL_WITH_SLASH}/user`,
    name: 'Users',
    svg: 'Account',
    id: 81,
    permit: MAINMODULE.USER,
  },
  {
    path: `${PREFIX_URL_WITH_SLASH}/crm`,
    name: 'CRM',
    svg: 'Account',
    id: 82,
    permit: MAINMODULE.CRM,
  },
  // {
  //   path: `${PREFIX_URL_WITH_SLASH}/utility`,
  //   name: 'UTILITY',
  //   svg: 'Account',
  //   id: 83,
  //   permit: MAINMODULE.UTILITY,
  // },
];

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { routerMiddleware } from 'connected-react-router';

import { history } from 'util/history';

import reducers from 'appRedux/reducers';

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];
const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
  : compose;

export const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));

import { API_ROUTES } from 'constants/CommonApi';
import UtilService from 'services/util';
import { isEmpty } from 'util/utils';

/**
 * Fucntion used to get user list
 * @param {*} request
 */
export const getUserList = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.paginate, request, ...(!isEmpty(config) && { config }) });

export const downloadUserExcel = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.downloadExcel, request, ...(!isEmpty(config) && { config }) });

/**
 * Function used to get user details
 * @param {*} id
 */
export const geUserDetails = (id = null, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.User.getDetail, [id]),
    ...(!isEmpty(config) && { config }),
  });

/**
 * Function used to update user details
 * @param {*} id
 * @param {*} request
 */
export const updateUserDetail = (id = null, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.User.update, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

  export const handleUserPermission = (id = null, request = {}) =>{
  const requests= {
    userId :id
  }
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.UserPermit.Paginate),
    requests,
  });
}

/**
 *  Function used to save user details
 * @param {*} request
 */
export const storeUserDetail = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.Upsert, request, ...(!isEmpty(config) && { config }) });

/**
 *  Function used to save user details
 * @param {*} request
 */
export const createMany = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.createMany, request, ...(!isEmpty(config) && { config }) });

/**
 * Fucntion used to change password
 * @param {*} request
 */
export const resetPassword = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.ResetPassword, request, ...(!isEmpty(config) && { config }) });

/**
 * Fucntion used take highEnd clients
 * @param {*} request
 */
export const getHighEndClient = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Account.getHighEndClient, request, ...(!isEmpty(config) && { config }) });

/**
 * get token from user id
 * @param {*} request
 */
export const getUserToken = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.generateToken, request, ...(!isEmpty(config) && { config }) });

/**
 * resend verification email to user
 * @param {*} request
 */
export const resendVerificationEmail = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.resenVerificationEmail, request, ...(!isEmpty(config) && { config }) });

/**
 * sync user manually
 * @param {*} request
 */
export const syncUserManually = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.manualUserSync, request, ...(!isEmpty(config) && { config }) });

const initialState = {};

export const FilterActionTypes = {
  Set: '@filter/set',
  Reset: '@filter/reset',
};

export const FilterActions = {
  Set: (payload) => ({ type: FilterActionTypes.Set, payload }),
  Reset: () => ({ type: FilterActionTypes.Reset }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FilterActionTypes.Set:
      return { ...state, ...payload };

    case FilterActionTypes.Reset:
      return { ...initialState };

    default:
      return state;
  }
};

import { Action } from 'services/action';
import { Storage } from 'services/storage';

import { isEmpty, isObject } from 'util/utils';

export class UserService {
  static get USER_ROLES() {
    return {
      SUPER_ADMIN: 1,
      ADMIN: 2,
      SUB_USER: 3,
      PRIMARY: 4,
      PERMANENT_GUEST: 5,
      EMPLOYEE: 6,
      API_USER: 7,
      SELLER: 8,
      CUSTOMER: 9,
      SECONDARY: 10,
    };
  }

  static data = [];

  static get updateUserEvent() {
    return new Action('@user/update');
  }

  static setData() {
    this.data = Storage.get('user') ?? {};
    this.updateUserEvent.emit(this.data);
  }

  static getCurrentUser() {
    if (!isObject(this.data) || isEmpty(this.data)) this.setData();
    return this.data;
  }

  static addListeners() {
    UserService.setData();
    void window.__UserListeners?.forEach?.((unlisten) => unlisten?.());
    window.__UserService = UserService;
    window.__UserListeners = [Storage?.listen?.('user', () => UserService.setData())];
  }
}

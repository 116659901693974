const INIT_STATE = {
    userPer:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'USER_PER': {
            return {
                ...state,
                userPer: action.payload
            };
        }
        default:
            return state;
    }
};

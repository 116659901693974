import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

setTimeout(() => {
  const { logInfo, runInDevelopment } = require('util/utils');
  runInDevelopment(() => {
    window.__history = history;
    history.listen(logInfo);
  });
}, 0);

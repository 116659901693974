import React from 'react';

import PropTypes from 'prop-types';

import { classNames } from 'util/classNames';
import { logWarn } from 'util/utils';

import loaderSvg from 'assets/images/loader.svg';

function CircularProgress({ className, message, visible = true, ...props }) {
  const loaderRef = React.useRef();

  const handleStyles = React.useCallback(() => {
    try {
      const computedStyle = getComputedStyle(loaderRef.current.parentElement);
      if (!computedStyle?.position || computedStyle?.position === 'static') {
        loaderRef.current.parentElement.style.position = 'relative';
      }

      loaderRef.current.style.visibility = visible ? 'visible' : 'hidden';
    } catch (error) {
      logWarn(error);
    }
  }, [visible]);

  React.useLayoutEffect(() => {
    handleStyles();
  }, [handleStyles]);

  return (
    <div ref={loaderRef} className={classNames([`loader`, className])} {...props}>
      {message ? <span>{message}</span> : <img src={loaderSvg} alt="loader" />}
    </div>
  );
}

CircularProgress.propTypes = {
  message: PropTypes.node,
};

export default React.memo(CircularProgress);

import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';

import { MasterService } from 'services/MasterService';
import { PermissionService } from 'services/PermissionService';
import { UserService } from 'services/UserService';
import { Storage } from 'services/storage';

import { loadable } from 'util/asyncComponent';
import { history } from 'util/history';
import * as utils from 'util/utils';

import { store } from 'appRedux/store';

import * as registerServiceWorker from './registerServiceWorker';

import 'styles/_main.less';

const App = loadable(() => import('containers/App'));
const ConnectedLoader = loadable(() => import('components/CircularProgress/ConnectedLoader'));

window.utils = utils;
Storage.addListeners();
UserService.addListeners();
MasterService.addListeners();
PermissionService.addListeners();

setTimeout(() => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
        <ConnectedLoader />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  );
});

registerServiceWorker.unregister();

import * as AccountAddressAPI from 'services/AccountAddressApi';
import { isArray } from 'util/utils';

const initialState = { loading: 0, accountAddresses: {} };

export const AccountAddressActionTypes = {
  SET_ACCOUNT_ADDRESS_LIST: 'SET_ACCOUNT_ADDRESS_LIST',
  GET_ACCOUNT_ADDRESS_LIST: 'GET_ACCOUNT_ADDRESS_LIST',
  START_ACCOUNT_ADDRESS_LOADING: 'START_ACCOUNT_ADDRESS_LOADING',
  STOP_ACCOUNT_ADDRESS_LOADING: 'STOP_ACCOUNT_ADDRESS_LOADING',
};

export const AccountAddressActions = {
  setAccountAddressList: (id, list) => ({
    type: AccountAddressActionTypes.SET_ACCOUNT_ADDRESS_LIST,
    payload: { id, list },
  }),
  getAccountAddressList: (id) => async (dispatch) => {
    dispatch({ type: AccountAddressActionTypes.START_ACCOUNT_ADDRESS_LOADING });
    const [, res] = await AccountAddressAPI.listAccountAddress({ account: id });

    const list = res?.data?.list;
    return dispatch({
      type: AccountAddressActionTypes.SET_ACCOUNT_ADDRESS_LIST,
      payload: { id, list: res?.code === 'OK' && isArray(list) ? list : [] },
    });
  },
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case AccountAddressActionTypes.START_ACCOUNT_ADDRESS_LOADING:
      return { ...state, loading: state.loading + 1 };

    case AccountAddressActionTypes.STOP_ACCOUNT_ADDRESS_LOADING:
      return { ...state, loading: state.loading - 1 };

    case AccountAddressActionTypes.SET_ACCOUNT_ADDRESS_LIST:
      return {
        ...state,
        loading: state.loading - 1,
        list: { ...state.list, [action?.payload?.id]: action?.payload?.list },
      };

    default:
      return { ...state };
  }
};
